import { Parser } from 'simple-text-parser';
import Helper from '@utils/misc';
const TextHelper = {
    linkify(value) {
        const parser = new Parser();
        parser.addRule(Helper.urlRegExp, function (v) {
            return `<a href="${v}" target="_blank">${v}</a>`;
        })

        return parser.render(value);
    },
}

export default TextHelper;
